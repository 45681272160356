import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import { companyHolder } from './company.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { correctColour } from '../utils/correctColour';

const duotoneVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '-24%',
    y: '21%',
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'anticipate',
    },
  },
  exit: {
    x: '0%',
    y: '0%',
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'anticipate',
    },
  },
};

const lighterColourVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '-30%',
    y: '-10%',
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'anticipate',
    },
  },
  exit: {
    x: '0%',
    y: '0%',
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'anticipate',
    },
  },
};

const darkerColourVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '20%',
    y: '15%',
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'anticipate',
    },
  },
  exit: {
    x: '0%',
    y: '0%',
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'anticipate',
    },
  },
};

const countryMapVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '25%',
    y: '-30%',
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'anticipate',
    },
  },
  exit: {
    x: '0%',
    y: '0%',
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'anticipate',
    },
  },
};

export default function CompanyPage({ data }) {
  const company = data.wpCompany;
  const featuredImage = getImage(company.companyInformation.featuredImage.localFile);
  const countryMap = getImage(company.companyInformation.countryMap.localFile);
  const duotoneImage = getImage(company.companyInformation.duotone.localFile);
  const logo = getImage(company.companyInformation.logo.localFile);

  const lessDarkColour = correctColour(company.companyInformation.darkerColour, 0.4);
  return (
    <article className={companyHolder}>
      <div className="images">
        <motion.div className="image featuredImage">
          <GatsbyImage image={featuredImage} alt={company.title} />
        </motion.div>
        <motion.div variants={duotoneVariant} className="image duotoneImage">
          <GatsbyImage image={duotoneImage} alt={company.title} />
        </motion.div>
        <motion.div className="image countryMap" variants={countryMapVariant}>
          <GatsbyImage image={countryMap} alt={company.title} objectFit="contain" />
        </motion.div>
        <motion.div
          variants={lighterColourVariant}
          className="image lighterColour"
          style={{ backgroundColor: company.companyInformation.lighterColour }}
        />
        <motion.div variants={darkerColourVariant} className="image darkerColour" style={{ backgroundColor: lessDarkColour }} />
      </div>
      <div className="about">
        <h1>{company.title}</h1>
        <h4>{company.companyInformation.country}</h4>
        <GatsbyImage image={logo} alt={company.title} className="logo" />
        <div className="bio" dangerouslySetInnerHTML={{ __html: company.companyInformation.about }} />
        <div className="website">
          <a href={company.companyInformation.websiteLink} title={`${company.title} website`} target="_blank" rel="noreferrer">
            Website
          </a>
        </div>
      </div>
    </article>
  );
}

export const Head = ({
  data: {
    wpCompany: { title },
  },
}) => <Seo title={`About ${title}`} />;

export const data = graphql`
  query CompanyTemplateQuery($id: String!, $darkerColour: String!, $lightestColour: String!) {
    wpCompany(id: { eq: $id }) {
      title
      id
      companyInformation {
        country
        about
        lighterColour
        darkerColour
        websiteLink
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 766, height: 1000, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
        duotone: featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 766
                height: 1000
                placeholder: BLURRED
                formats: AUTO
                transformOptions: { duotone: { highlight: $lightestColour, shadow: $darkerColour } }
              )
            }
          }
        }
        countryMap {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: BLURRED, formats: AUTO, quality: 100)
            }
          }
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, placeholder: TRACED_SVG, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;
